/***
 *
 *********** Homepage animations HUB ***********
 *
 ***/
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

/**
 * Logos carousel animation
 */
let duration = 30;
let tl = gsap.timeline();
let delayFactor = null;
export const carouselAnimation = (separation, distance, list, reverse, refs) => {
    //set delay factor. delay factor helps for positioning element in a timeline
    delayFactor = ( duration / list.length)
    const updateTl = (tween) => {
        //add our animation to a timeline for better control
        tl.add(tween, delayFactor)
    }

    //animation logics function
    const animateCards = (e, i) => {
        let target = refs.current[i].current;
        // initial position
        gsap.set(target, {x: (i * separation), autoAlpha: 0});
        // show cards
        gsap.to(target, 0.3, {autoAlpha: 0.5});
        // animation
        const cardTween = gsap.to(target, duration, {
            ease: "none",
            x: `${reverse ? "-" : "+"}=${distance}`,
            repeat: -1,
            modifiers: {
                x: gsap.utils.unitize(x => {
                    return reverse ? ((parseFloat(x) < 0) ? ((parseFloat(x) % distance) + distance) : (parseFloat(x))) :
                        (parseFloat(x) % distance)
                })
            }
        });

        updateTl(cardTween)
    }

    if (separation) {
        list.forEach(animateCards)
    }

    return tl; //always return timeline or tween in order to kill on component unmount
}

export const logoStoppedAnimation = (ref) => {
    //hover animation here ( always done by ref )
    let target = ref.current;
    //scale and change opacity
    gsap.to(target, 0.4, {scaleX:1.1, scaleY:1.1, autoAlpha: 1});
    //remember to stop te current animation
    tl.pause()
}

export const logoLeaveAnimation = (ref) => {
    //hover exit animation here ( always done by ref )
    let target = ref.current;
    //scale off and decrease opacity
    gsap.to(target, 0.4, {scaleX:1, scaleY:1, autoAlpha: 0.5});
    //remember to stop te current line animation
    tl.play()
}

/**
 * Hero drop animation
 */
export const dropCursorMirror = (evt,dropRef) => {
    const maxX = gsap.getProperty(dropRef?.current, "width") * 0.75;
    const maxY = gsap.getProperty(dropRef?.current, "height") * 0.75;
    const percent = gsap.utils.normalize(0, innerWidth, evt?.pageX);
    const percentY = gsap.utils.normalize(0, innerHeight, evt?.pageY);

    return gsap.to(dropRef?.current ,{
        duration: 0.2,
        x: percent * maxX - maxX / 2,
        y: percentY * maxY - maxY / 2,
        overwrite: true,
        paused: true
    });
}

/**
 * Scroller section animation
 */
export const scrollerSectionsAnimations = (trigger, lastMediaName, topDescription) => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.set(".panel", { zIndex: (i, target, targets) => targets.length - i });
    let medias = gsap.utils.toArray(`.panel:not(.${lastMediaName})`);
    medias.forEach((media, i) => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: trigger.current,
                start: () => "top -" + (window.innerHeight*(i+0.5)),
                end: () => "+=" + window.innerHeight,
                scrub: true,
                toggleActions: "play none reverse none",
                invalidateOnRefresh: true,
            }
        })
        tl.to(media, { y: -800 , borderBottomRightRadius: "1400px", borderBottomLeftRadius: "1400px" });
    });

    gsap.set(".panel-text", { zIndex: (i, target, targets) => targets.length + i });
    let texts = gsap.utils.toArray('.panel-text');
    texts.forEach((text, i) => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: trigger.current,
                start: () => "top -" + (window.innerHeight*i),
                end: () => "+=" + window.innerHeight,
                scrub: true,
                toggleActions: "play none reverse none",
                invalidateOnRefresh: true,
            }
        })
        tl.to(text, { duration: 0.33, opacity: 1, y: topDescription ? '33%' : '55%' })
            .to(text, { duration: 0.33, opacity: 0, y:"0%" }, 0.33);
    });

    ScrollTrigger.create({
        trigger: trigger.current,
        scrub: true,
        markers: false,
        pin: trigger.current,
        start: () => "top top",
        end: () => "+=" + ((medias.length + 1) * window.innerHeight),
        invalidateOnRefresh: true,
    });
}

/**
 * Dark section animation
 */
export const scrollerDarkInfosAnimations = (trigger,section, setIsDarkCb) => {
    gsap.registerPlugin(ScrollTrigger);
    let texts = gsap.utils.toArray('.custom-color');

    const changeColors = (isReverse = false) => {
        texts.forEach((text, i) => {
            let tl = gsap.timeline();
            tl.to(text, {color: isReverse ? "#1D0F41" : "#fff",duration: 0.5, onStart: () => setIsDarkCb(!isReverse)})
            tl.to(section.current, { background: isReverse ? "#fff": "#1D0F41",duration: 0.3, onStart: () => setIsDarkCb(!isReverse)})
        });
    }

    ScrollTrigger.create({
        trigger: trigger.current,
        scrub: true,
        markers: false,
        pin: trigger.current,
        start: "-=80",
        endTrigger: '.end-scroll-title',
        end: "top 76px",
        onEnter: () => changeColors(),
        onLeaveBack: () => changeColors(true),
        invalidateOnRefresh: true,
    });
}
