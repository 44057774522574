import React from "react"
import withLayout from "../layout"
import {FormattedMessage, injectIntl} from "react-intl"
import SEO from "../components/SEO"
import {Heading} from "../components/UI/Typography"
import Hero from "../components/Hero"
import carouselCards from "../utilities/webinars"
import Carousel from "../components/UI/Carousel"
import {isPastDate} from "../utilities/helpers"

const EventsPage = ({intl}) => {
    const keywords = intl.formatMessage({id: "events.meta_keywords"}).split(",") || []
    const nextWebinars = carouselCards.filter(({date, times}) => (
        !!times
            ? !isPastDate(`${date} ${times[0]}`)
            : !isPastDate(date)
    ))
    const prevWebinars = carouselCards.filter(({date, times}) => (
        !!times
            ? isPastDate(`${date} ${times[0]}`)
            : isPastDate(date)
    ))

    return (
        <div>
            <SEO
                title={intl.formatMessage({id: "events.meta_title"})}
                description={intl.formatMessage({id: "events.meta_description"})}
                keywords={keywords}
                lang={intl.locale}
            />

            <Hero
                title="events.hero_title"
                thinChild="3"
                paragraph="events.hero_subtitle"
            />
            <div className="section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Heading level="3" centered>
                                <FormattedMessage id="events.next_webinars"/>
                            </Heading>
                        </div>
                    </div>
                </div>
                <Carousel
                    list={nextWebinars}
                    imageWidth={480}
                    slideNextOnTap={false}
                    autoplay={false}
                    intl={intl}
                />
            </div>
            <div
                className="section-padding-top section-padding-bottom"
                style={{backgroundColor: "#F4F5F8"}}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Heading level="3" centered>
                                <FormattedMessage id="events.prev_webinars"/>
                            </Heading>
                        </div>
                    </div>
                </div>
                <Carousel
                    list={prevWebinars}
                    imageWidth={480}
                    autoplay={false}
                    slideNextOnTap={false}
                    intl={intl}
                />
            </div>
        </div>
    )
}

const customProps = {
    localeKey: "events", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(EventsPage))
