import React, {useEffect, useRef, useState} from 'react';
import {ThemeProvider} from "styled-components";
import Header from '../Header';
import DropBg from '../UI/DropBg';
import DropBig from '../../images/bkg_drop_header.svg';
import DropSmall from '../../images/jobs/DropSmall.svg';
import {Heading, Paragraph} from '../UI/Typography'
import {FormattedMessage} from "react-intl";
import dark from "../../theme/dark";
import Fade from "react-reveal/Fade.js";
import {PlayIcon} from "../../images/icons";
import VideoPlayer from "./VideoPlayer";
import light from "../../theme/light";
import {dropCursorMirror} from "../../animations/homePage";
import {isTablet, isMobile} from "react-device-detect";

const Hero = ({title, thinChild, subtitle, paragraph, isVideo = false, hasModalVideo = false, srcVideo, isDark = true}) => {
    const [showPlayer, setShowPlayer] = useState(false);
    const dropRef = useRef(null);

    useEffect(() => {
        if(dropRef.current && !isMobile && !isTablet) {
            window?.addEventListener('mousemove', playDropAnimation);

            return () => {
                window?.removeEventListener('mousemove', playDropAnimation);
            }
        }
    }, []);

    const playDropAnimation = (e) => {
        const dropAnimation = dropCursorMirror(e, dropRef);
        dropAnimation.play();
    };

    return (
        <ThemeProvider theme={isDark ? dark : light} colors={isDark ? dark.colors : null}>
            <div className={`Hero d-flex justify-content-center align-items-center content ${isDark ? 'Hero-dark' : 'Hero-light'}`}>
                <Header headerStyle={isDark ? "dark" : "light"}/>
                {isVideo && <div className="video"
                    dangerouslySetInnerHTML={{
                        __html: `
                       <video
                         loop
                         muted
                         autoplay
                         playsinline
                         webkit-playsinline
                         src="${srcVideo}""
                       />,
                     `
                    }}>
                </div>}
                {showPlayer && (
                    <VideoPlayer
                        className="player"
                        autoplay
                        closeCb={() => setShowPlayer(false)}
                        vimeoId="515405167"
                        title="The Strange - Kromin"
                    />
                )}
                <div className={isVideo ? "overlay" : ""}>
                    <div className="container">
                        {(!isVideo && isDark) && <DropBg src={DropBig} top={"200px"} right={"-100px"} width={"426px"} height={"412px"} blur={true}/>}
                        <div className="row position-relative">
                            {!isVideo && <DropBg src={DropSmall} right={"15px"} top={'0%'} hideOnMobile={true} ref={dropRef}/>}
                            <div className="col-md-9 col-lg-8 col-12">
                                <Fade bottom delay={500}>
                                    <Heading level={"1"} topDot thinChild={thinChild}>
                                        <FormattedMessage id={title}/> <br/>
                                        {subtitle && <FormattedMessage id={subtitle}/>}
                                    </Heading>
                                </Fade>
                                <Fade bottom delay={800}>
                                    <Paragraph customColor={isDark ? "white" : "violetDarken200"}>
                                        <FormattedMessage id={paragraph}/>
                                    </Paragraph>
                                </Fade>
                                {hasModalVideo && <Fade bottom delay={900}>
                                    <label className={"special-label pointer mt-4 clickable"} onClick={() => setShowPlayer(true)}>
                                        <PlayIcon/>
                                        <FormattedMessage id={"agency.see_video"}/>
                                    </label>
                                </Fade>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}


export default Hero;
